<template>
	<b-card no-body class="web p-3">
		<div v-if="isAllowedSelectDay && !readonly">
			<h5>Preferované dni</h5>
			<p>
				Kliknutím na deň prosím označte, v ktoré dni bude Vaše dieťa kurz navštevovať. Je možná aj viacnásobná voľba. Opätovné kliknutie označený deň odznačí. Zvolené dni sa predvyplnia na všetky týždne trvania kurzu. Upraviť jednotlivé týždne môžete v tabuľke nižšie.
			</p>
		</div>
		<div v-if="isAllowedSelectDay && !readonly" class="days py-3">
			<z-day v-for="(day, i) in ['d1', 'd2', 'd3', 'd4', 'd5', 'd6', 'd0']" :key="i" :day="day" :disabled="!activeWeekDays.includes(day)" :selected="preferredWeekDays.includes(day)" @click.native="selectDay(day)" />
		</div>

		<div v-if="isAllowedSelectDay && !readonly">
			<p>
				Výber dní môžete upresniť pre jednotlivé týždne kurzu (pre každé dieťa zvlášť) podľa Vašich preferencií.
				<!--	Čím viac lekcií/termínov v kalendári zvolíte, tým výhodnejšiu cenu získate. -->
			</p>
			<div class="bg-light rounded p-2 d-flex" v-if="orderProduct.minimum_selected_days"><img src="@/theme/svg/check_circle_outline.svg" class="mr-2" />Pre tento kurz musíte zvoliť minimálne {{ orderProduct.minimum_selected_days }} dni.</div>

			<h5>Legenda</h5>
			<div class="d-flex align-items-center legend">
				<div class="d-flex align-items-center"><z-day class="mr-4 disabled-day" day="Ne" :disabled="true" /> - Nedostupný termín</div>

				<div class="d-flex align-items-center ml-5"><z-day class="mr-4" day="Ne" :occupied="true" /> - Termín už je obsadený</div>

				<div class="d-flex align-items-center ml-5"><z-day class="mr-4" day="Ne" :selected="true" /> - Vybratý termín</div>
			</div>
		</div>

		<z-reservation-calendar :selDays="selectedDays" :readonly="readonly" :enabledDays="orderRegistration.product_period.available_dates" :occupiedDays="orderRegistration.product_period.occupied_dates" :fromDate="new Date(orderRegistration.product_period.start_date)" :toDate="new Date(orderRegistration.product_period.end_date)" @day-selected="onDaySelected" @available-week-days-changed="onAvailableWeekDaysChanged" />
	</b-card>
</template>

<script>
import moment from 'moment'

export default {
	components: {
		'z-day': () => import('@/plugins/app@components/day/z-day.vue')
	},

	props: {
		orderRegistration: { type: Object, required: true },
		readonly: { type: Boolean, default: false }
	},

	data() {
		return {
			preferredWeekDays: [...(this.orderRegistration.fe_custom_data.preferred_days || [])],
			activeWeekDays: []
		}
	},

	mounted() {
		if (this.$route.query.dni) {
			const days = this.$route.query.dni.split('-')
			days.forEach((day) => {
				this.prefillDaysFromUrl(day)
			})
		}
	},

	computed: {
		selectedDays: {
			get() {
				if (!this.orderSelectedDays) return {}

				return this.orderSelectedDays.reduce((daysMap, date) => {
					const [y, m, d] = date.date.split('-')
					daysMap[`${y}-${m}-${d}`] = {
						id: date.id,
						selected: true
					}
					return daysMap
				}, {})
			},
			set(val) {
				this.$emit('day-selected', val)
			}
		},

		orderSelectedDays() {
			return this.orderRegistration.order_registration_dates
		},

		orderProduct() {
			return this.orderRegistration.product_period.product
		},

		isAllowedSelectDay() {
			return this.orderRegistration.product_period.is_allowed_select_day == '1'
		}
	},

	watch: {
		orderSelectedDays() {
			if (this.orderSelectedDays.length) return

			this.preferredWeekDays = []
		},

		activeWeekDays() {
			if (this.$route.query.dni && this.activeWeekDays && !this.preferredWeekDays.length) {
				const days = this.$route.query.dni.split('-')
				days.forEach((day) => {
					this.prefillDaysFromUrl(day)
				})
			}
		}
	},

	methods: {
		onDaySelected(selectedDay) {
			const val = this.orderSelectedDays.filter((day) => day.date == selectedDay.date).length ? this.orderSelectedDays.filter((day) => day.date != selectedDay.date) : [...this.orderSelectedDays, selectedDay]
			this.$emit('day-selected', val)
		},

		onAvailableWeekDaysChanged(availableWeekDays) {
			this.activeWeekDays = [...availableWeekDays]
		},

		selectDay(day) {
			if (!this.activeWeekDays.includes(day)) return

			if (this.preferredWeekDays.includes(day)) this.preferredWeekDays.splice(this.preferredWeekDays.indexOf(day), 1)
			else this.preferredWeekDays.push(day)

			this.$emit('preferred-days-changed', this.preferredWeekDays)

			const today = new Date(new Date().setHours(0, 0, 0, 0))
			today.setDate(today.getDate() + 2)

			const productStartDate = new Date(this.orderRegistration.product_period.start_date)
			const productEndDate = new Date(this.orderRegistration.product_period.end_date)
			const currentDate = today.getTime() > productStartDate.getTime() ? today : productStartDate
			while (currentDate <= productEndDate) {
				const currentDayIndex = 'd' + currentDate.getDay()
				const date = moment(currentDate).format('YYYY-MM-DD')

				if (currentDayIndex == day && this.orderRegistration.product_period.available_dates.includes(date)) {
					if (this.preferredWeekDays.includes(day)) this.selectedDays[date] = this.selectedDays[date] || {}
					else delete this.selectedDays[date]
				}

				currentDate.setDate(currentDate.getDate() + 1)
			}

			this.selectedDays = Object.entries(this.selectedDays).map(([key]) => ({ date: key }))
		},

		prefillDaysFromUrl(day) {
			if (day == 'pondelky') this.selectDay('d1')
			if (day == 'utorky') this.selectDay('d2')
			if (day == 'stredy') this.selectDay('d3')
			if (day == 'stvrtky') this.selectDay('d4')
			if (day == 'piatky') this.selectDay('d5')
			if (day == 'soboty') this.selectDay('d6')
			if (day == 'nedele') this.selectDay('d7')
		},

		DEV_prefill() {
			this.selectDay('d1')
			this.selectDay('d3')
		}
	}
}
</script>

<style lang="sass" scoped>
.disabled-day
	color: #b3b2b0 !important
	border: 2px solid #b3b2b0 !important
.week
	display: flex

.days
	display: flex
	justify-content: center
@media (max-width: 767px)
	.legend
		display: inline!important
		div
			margin-left: 0!important
</style>
